@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Merriweather', Georgia, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .container {
    @apply px-4 mx-auto max-w-7xl;
  }
  
  .btn {
    @apply px-6 py-2 font-medium transition-colors rounded-md;
  }
  
  .btn-primary {
    @apply text-white bg-primary hover:bg-blue-900;
  }
  
  .btn-secondary {
    @apply text-white bg-secondary hover:bg-amber-600;
  }
}
